var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-form p-20"},[(_vm.fioErredEn)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fioErred),expression:"fioErred"}],staticClass:"form-tooltip"},[_vm._v(" Недопустимый символ ")]):_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fioErred),expression:"fioErred"}],staticClass:"form-tooltip"},[_vm._v(" Введите имя кириллицей ")]),_c('app-input',{ref:"username",class:_vm.notValid ? '' : _vm.fioErred ? 'erred' : 'not-erred',attrs:{"placeholder":"Имя","icon":_vm.notValid
        ? 'person'
        : _vm.fioErred
        ? 'erred'
        : 'not-erred',"disabled":_vm.loading},on:{"submit":_vm.onSubmit},model:{value:(_vm.user.fio),callback:function ($$v) {_vm.$set(_vm.user, "fio", $$v)},expression:"user.fio"}}),_c('app-input',{class:_vm.notValid ? '' : _vm.phoneErred ? 'erred' : 'not-erred',attrs:{"placeholder":"Номер телефона","icon":_vm.notValid
        ? 'phone'
        : _vm.phoneErred
        ? 'erred'
        : 'not-erred',"mask":"+9 (999) 999 99 99","type":"tel","disabled":_vm.loading},on:{"submit":_vm.onSubmit},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.phoneErred),expression:"phoneErred"}],staticClass:"form-tooltip"},[_vm._v(" Номер должен содержать 11 цифр ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.source),expression:"user.source"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.user.source)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "source", $event.target.value)}}}),_c('app-icon-button',{staticClass:"user-form-submit",attrs:{"id":"continue-button","color":"#0095da","disabled":_vm.notValid || _vm.notValidName || _vm.notValidPhone || _vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(" Продолжить ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }